/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-plusplus */

import { DownshiftProps, PropGetters } from 'downshift';
import { isEmpty } from 'lodash';
import React, { forwardRef } from 'react';
import { createPortal } from 'react-dom';
import { BoxProps } from 'reflexbox';

import { Box } from '@/components/box';
import { OptionGroup, OptionItem, RectPositionInterface } from '@/types';

import { Dropdown, Item, OptGroup, OptGroupTitle } from './shared-components';

interface Props extends BoxProps, DownshiftProps<OptionItem>, PropGetters<OptionItem> {
  options: OptionGroup[];
  selected?: string | number;
  convertItemToString?: (item: OptionItem) => string;
  buttonWidth?: string;
  dropdownWidth?: string;
  dropdownPosition?: RectPositionInterface;
  onSelect?: (value: OptionItem | null) => void;
  selectItem?: (item: OptionItem) => void;
  className?: string;
  size: 'small' | 'medium';
}

const Menu = forwardRef(
  (
    {
      options,
      getItemProps,
      convertItemToString,
      dropdownWidth,
      dropdownPosition,
      selectedItem,
      selectItem,
      highlightedIndex,
      isOpen,
      size,
      ...props
    }: Props,
    ref,
  ) => {
    return (
      <Box data-testid="listbox-wrapper">
        {isOpen &&
          createPortal(
            <Box ref={ref} {...props}>
              <Dropdown
                initial="hidden"
                exit="hidden"
                animate={isOpen ? 'open' : 'hidden'}
                variants={{
                  open: { opacity: 1, scale: 1 },
                  hidden: { opacity: 0, scale: 0.8 },
                }}
                position={dropdownPosition}
                dropdownWidth={dropdownWidth}
                size={size}
              >
                {
                  options.reduce(
                    (result, group, groupIndex) => {
                      // If the group is empty, don't render it
                      if (isEmpty(group.items)) {
                        return result;
                      }
                      result.groups.push(
                        // eslint-disable-next-line react/no-array-index-key
                        <OptGroup key={groupIndex}>
                          {group.title && <OptGroupTitle>{group.title}</OptGroupTitle>}
                          {group.items.map((item) => {
                            // eslint-disable-next-line no-param-reassign
                            const itemIndex = result.itemIndex++;
                            return (
                              <Item
                                key={item.value}
                                {...getItemProps({
                                  item,
                                  index: itemIndex,
                                  onMouseUp: selectItem ? () => selectItem(item) : undefined,
                                  disabled: item.disabled,
                                })}
                                highlighted={itemIndex === highlightedIndex}
                                selected={selectedItem?.value && selectedItem.value === item.value}
                                className="select-box-item"
                              >
                                {convertItemToString ? convertItemToString(item) : null}
                              </Item>
                            );
                          })}
                        </OptGroup>,
                      );

                      return result;
                    },
                    { groups: [] as React.ReactNode[], itemIndex: 0 },
                  ).groups
                }
              </Dropdown>
            </Box>,
            document.body,
          )}
      </Box>
    );
  },
);

Menu.displayName = 'Menu';

export { Menu };
