import { get } from 'lodash';
import { forwardRef } from 'react';

import { Error } from '@/components/inputs/error';
import { Label } from '@/components/inputs/label';
import { Field, FieldErrors } from '@/types/form-types';
import { tv } from '@/utils/styles';

type Props = Field &
  FieldErrors & {
    label: string;
    variant?: 'light' | 'dark';
    alignment?: 'vertical' | 'horizontal';
    isOptional?: boolean;
    size?: 'small' | 'medium';
    name: string;
  };

const triggerInputUpdate = (inputId: string, newValue: number) => {
  const input = document.getElementById(inputId) as HTMLInputElement;

  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')?.set;
  nativeInputValueSetter?.call(input, newValue);

  const inputEvent = new Event('input', { bubbles: true });
  input.dispatchEvent(inputEvent);
};

const NumberSpinner = forwardRef<HTMLDivElement, Props>(
  (
    {
      label,
      hideLabel,
      errors,
      variant = 'light',
      alignment = 'vertical',
      name,
      value,
      isOptional,
      size = 'medium',
      min = 1,
      max = 10,
      ...props
    }: Props,
    ref,
  ) => {
    const fieldError = get(errors, name);

    const handleOnIncrement = () => {
      const newValue = Number(value) + 1;
      if (newValue <= Number(max)) {
        triggerInputUpdate(name, newValue);
      }
    };

    const handleOnDecrement = () => {
      const newValue = Number(value) - 1;
      if (newValue >= Number(min)) {
        triggerInputUpdate(name, newValue);
      }
    };

    const preventKeyboardInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
      event.preventDefault();
    };

    const { base, inputWrapper, input, spinner, error, labelStyles } = numberSpinnerStyles({
      variant,
      alignment,
      size,
      fieldError: !!fieldError,
    });

    return (
      <div className={base()} ref={ref}>
        <Label
          htmlFor={name}
          isHidden={hideLabel}
          isOptional={isOptional}
          alignment={alignment}
          className={labelStyles()}
        >
          {label}
        </Label>
        <div className={inputWrapper()}>
          <button onClick={() => handleOnDecrement()} data-testid="decrement" type="button" className={spinner()}>
            -
          </button>
          <input
            id={name}
            name={name}
            tabIndex="-1"
            type="text"
            value={value}
            height={size}
            onKeyDown={preventKeyboardInput}
            className={input()}
            {...props}
            {...errors}
          />
          <button onClick={() => handleOnIncrement()} data-testid="increment" type="button" className={spinner()}>
            +
          </button>
        </div>
        {fieldError?.message && <Error className={error()}>{errors[name].message}</Error>}
      </div>
    );
  },
);

const numberSpinnerStyles = tv({
  slots: {
    base: 'relative',
    inputWrapper: 'flex items-center overflow-hidden rounded-md border border-grey-300 shadow-softer',
    input:
      'h-[calc(var(--input-height)-3px)] w-full flex-1 appearance-none !border-none text-center transition-all duration-200 ease-in-out',
    spinner: 'h-[calc(var(--input-height)-3px)] w-[var(--input-height)] bg-grey-100 hover:bg-grey-200',
    error: 'text-forms-states-error',
    labelStyles: null,
  },
  variants: {
    variant: {
      light: {},
      dark: {},
    },
    alignment: {
      vertical: '',
      horizontal: {
        base: 'flex items-center whitespace-nowrap',
        labelStyles: 'mr-2',
      },
    },
    size: {
      small: {
        input: 'max-h-[30px]',
        spinner: 'h-[30px] w-[30px]',
      },
      medium: {
        input: 'max-h-[calc(var(--input-height)-3px)]',
      },
    },
    fieldError: {
      true: {
        inputWrapper: 'border-forms-states-error !shadow-[0_0_0_1px_theme(colors.forms.states.error)]',
        input: 'text-forms-states-error',
      },
    },
  },
});

NumberSpinner.displayName = 'NumberSpinner';

export { NumberSpinner };
