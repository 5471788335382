import { isEmpty } from 'lodash';

import { OptionGroup, OptionItem } from '@/types';

const convertItemToString = (item: OptionItem | null) => item?.label || '';

const flattenGroupOptions = (opts: OptionGroup[]) =>
  opts.reduce((prev, curr) => {
    return [...prev, ...curr.items];
  }, [] as OptionItem[]);

/**
 * Return the full item if the search term is found
 * at the start of any of the words in the item
 */
const matchItemThatHasSearchTerm = (item: string, searchTerm: string) => {
  const regex = new RegExp(`\\b${searchTerm}.*\\b`, 'gm');
  return regex.test(item);
};

/**
 * Filter each options.items array based on inputValue
 */
const getFilteredOptions = (inputValue: string, options: OptionGroup[]) => {
  const filteredOptionsArray = options.map((option) => {
    const filteredItems = option.items.filter((item) => {
      const itemLabel = convertItemToString(item);
      return matchItemThatHasSearchTerm(itemLabel.toLowerCase(), inputValue.toLowerCase());
    });

    return { ...option, items: filteredItems };
  });

  // If all items in filteredOptionsArray are empty show no results message
  if (filteredOptionsArray.every((option) => isEmpty(option.items))) {
    return [
      {
        items: [{ label: 'No results found', value: '', disabled: true }],
      },
    ];
  }

  return filteredOptionsArray;
};

const checkIfOnlyOneItemLeft = (filteredOptions: OptionGroup[]) => {
  const itemsLeft = filteredOptions.reduce((acc, curr) => {
    return acc + curr.items.length;
  }, 0);

  return itemsLeft === 1;
};

export {
  checkIfOnlyOneItemLeft,
  convertItemToString,
  flattenGroupOptions,
  getFilteredOptions,
  matchItemThatHasSearchTerm,
};
