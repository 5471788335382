import { Box as styledBox } from 'reflexbox/styled-components';
import styled from 'styled-components';
import { background, border, color, flexbox, position, shadow, typography } from 'styled-system';

const Box = styled(styledBox)<any>`
  ${background}
  ${border};
  ${color};
  ${position};
  ${flexbox};
  ${shadow};
  ${typography};
`;

export { Box };
