import { get } from 'lodash';

import { FieldErrors } from '@/types/form-types';
import { tv } from '@/utils/styles';

import { Error } from '../error';

export interface RadioGroupProps extends FieldErrors {
  name: string;
  children: React.ReactNode;
  variant?: 'default' | 'halves' | 'thirds';
}

const RadioGroup = ({ children, name, errors, variant = 'default', ...props }: RadioGroupProps) => {
  const fieldError = get(errors, name);

  const { base, fieldset, error } = radioGroupStyles({ variant, fieldError: !!fieldError });

  return (
    <div className={base()} {...props}>
      <fieldset className={fieldset()}>{children}</fieldset>
      {fieldError?.message && <Error className={error()}>{fieldError.message}</Error>}
    </div>
  );
};

const radioGroupStyles = tv({
  slots: {
    base: null,
    fieldset: 'flex flex-wrap',
    error: 'mt-2',
  },
  variants: {
    variant: {
      default: {
        fieldset: 'flex items-center gap-2 pt-1',
      },
      halves: {
        fieldset: 'grid items-center gap-2 sm:grid-cols-2 sm:gap-4',
      },
      thirds: {
        fieldset: 'grid items-center gap-2 sm:grid-cols-2 sm:gap-4 md:grid-cols-3',
      },
    },
    fieldError: {
      true: {
        base: '[&_.radio-button]:border-forms-states-error [&_.radio-button]:text-forms-states-error [&_.radio-button]:shadow-[0_0_0_1px_theme(colors.forms.states.error)]',
        error: 'text-forms-states-error',
      },
    },
  },
});

export { RadioGroup };
