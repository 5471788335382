import themeGet from '@styled-system/theme-get';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { variant as styledVariant } from 'styled-system';

import { DROPDOWN_WIDTH } from '@/lib/constants';
import { RectPositionInterface } from '@/types';

export interface SelectInputProps {
  isOpen?: boolean;
  isActive: boolean;
  minWidth?: string;
  maxWidth?: string;
  buttonWidth?: string;
  isTypeahead?: boolean;
  showShadow?: boolean;
}

export const ButtonIndicator = styled.span`
  align-items: center;
  background-color: ${themeGet('colors.lightBlue.500')};
  border-radius: ${themeGet('radii.default')};
  border: 1px solid ${themeGet('colors.lightBlue.700')};
  color: ${themeGet('colors.white')};
  display: flex;
  font-size: ${themeGet('fontSizes.sm')};
  font-weight: ${themeGet('fontWeights.medium')};
  justify-content: center;
  line-height: ${themeGet('lineHeights.none')};
  margin-left: ${themeGet('space.sm')};
  padding: ${themeGet('space.xs')};
  text-align: center;
  min-width: ${themeGet('space.5')};
`;

interface DropdownProps {
  dropdownWidth?: string;
  position?: RectPositionInterface;
  size: 'small' | 'medium';
}

const dropdownSizeVariants = styledVariant({
  scale: 'forms.selectBoxDropdown',
  prop: 'size',
  variants: {
    medium: {},
    small: {},
  },
});

export const Dropdown = styled(motion.div).withConfig({
  shouldForwardProp: (prop) => !['dropdownWidth', 'position', 'size'].includes(prop),
})<DropdownProps>`
  ${dropdownSizeVariants};
  position: absolute;
  z-index: ${themeGet('zIndices.10')};
  margin-top: ${themeGet('space.xs')};
  border-radius: ${themeGet('radii.md')};
  border: 1px solid ${themeGet('colors.grey.300')};
  background: ${themeGet('colors.white')};
  width: ${({ dropdownWidth }) => dropdownWidth || '100%'};
  transform-origin: top left;
  box-shadow: ${themeGet('shadows.softer')};
  max-height: 500px;
  max-width: ${DROPDOWN_WIDTH};
  overflow: auto;
  top: ${({ position }) => position?.top || '0'}px;
  left: ${({ position }) => position?.left || '0'}px;
`;

interface ItemProps {
  highlighted: boolean;
  selected: boolean;
  disabled?: boolean;
}

export const Item = styled.div<ItemProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: ${themeGet('radii.default')};
  background-color: ${themeGet('colors.white')};
  cursor: pointer;

  ${({ highlighted }) =>
    highlighted &&
    css`
      background-color: ${themeGet('colors.blue.50')};
    `}

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${themeGet('colors.blue.50')};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      color: ${themeGet('colors.grey.350')};
      background-color: transparent; // Disabled items will still be highlighted but you can't select them, this just makes them appear unhighlighted
    `}
`;

export const OptGroup = styled.div`
  & + & {
    margin-top: ${themeGet('space.base')};
    padding-top: ${themeGet('space.base')};
    border-top: dashed 1px ${themeGet('colors.grey.300')};
  }
`;

export const OptGroupTitle = styled.div`
  padding: ${themeGet('space.xs')} ${themeGet('space.sm')};
  font-size: ${themeGet('fontSizes.sm')};
  color: ${themeGet('colors.grey.900')};
  font-weight: ${themeGet('fontWeights.semiBold')};
  text-transform: uppercase;
`;
