import { Flex as styledFlex } from 'reflexbox/styled-components';
import styled from 'styled-components';
import { border, display, layout, position } from 'styled-system';

const Flex = styled(styledFlex)<any>`
  ${border};
  ${display}
  ${position};
  ${layout};
`;

export { Flex };
