import { Text } from '@/components/text';
import { tv } from '@/utils/styles';

interface Props {
  children: React.ReactNode | string;
  className?: string;
  variant?: 'solid';
}

const Error = ({ children, className, variant, ...props }: Props) => {
  const { base, text } = styles({ variant });
  return (
    <div className={base({ className })} {...props}>
      <Text variant="secondary" className={text()}>
        {children}
      </Text>
    </div>
  );
};

const styles = tv({
  slots: {
    base: 'mt-2',
    text: 'mx-2 my-0 font-semibold',
  },
  variants: {
    variant: {
      solid: {
        base: '!m-0',
        text: '-mx-px mt-0 rounded-b bg-forms-states-error px-2 py-1 text-sm font-normal text-white',
      },
    },
  },
});

export { Error };
