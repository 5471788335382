/**
 * Replaced by @/components/ui/inputs/select
 * Do not use, delete once confirmed new Select
 * is working well in production
 */

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-plusplus */

import { themeGet } from '@styled-system/theme-get';
import { useCombobox, UseComboboxState, UseComboboxStateChangeOptions } from 'downshift';
import { debounce, get, isFunction } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { BoxProps } from 'reflexbox';
import styled, { css } from 'styled-components';
import { layout, variant as styledVariant } from 'styled-system';

import { Box } from '@/components/box';
import { Flex } from '@/components/flex';
import { Icon } from '@/components/icon';
import { Label } from '@/components/inputs/label';
import { Tooltip } from '@/components/tooltip';
import { VisuallyHidden } from '@/components/visually-hidden';
import { lockedDialogIsOpenState } from '@/state/user';
import { colors } from '@/theme/constants';
import { OptionGroup, OptionItem, RectPositionInterface } from '@/types';
import { FieldErrors } from '@/types/form-types';
import { trackUser } from '@/utils/tracking';

import { Error } from '../error';
import { Menu } from './menu';
import { SelectInputProps } from './shared-components';
import { checkIfOnlyOneItemLeft, convertItemToString, flattenGroupOptions, getFilteredOptions } from './utils';

const DEBOUNCE_DELAY = 300;

interface Props extends BoxProps, FieldErrors {
  id: string;
  label?: string;
  placeholder: string;
  options: OptionGroup[];
  selected?: string | number;
  defaultSelectedItem?: any | null;
  disabled?: boolean;
  minWidth?: string;
  maxWidth?: string;
  buttonWidth?: string;
  dropdownWidth?: string;
  onSelect?: (value: OptionItem) => void;
  onBlur?: () => void;
  isActive?: boolean;
  trackingCategory?: string;
  watchForOptionsChange?: boolean;
  variant?: 'light' | 'dark';
  size?: 'small' | 'medium';
  isTypeahead?: boolean;
  showShadow?: boolean;
  isLocked?: boolean;
  hideErrorMessage?: boolean;
  tooltipContent?: React.ReactNode | string;
}

const SelectBox = ({
  id,
  label,
  placeholder,
  minWidth,
  maxWidth,
  buttonWidth,
  dropdownWidth,
  options,
  selected,
  defaultSelectedItem,
  disabled,
  onSelect,
  onBlur,
  isActive = false,
  watchForOptionsChange = false,
  variant = 'light',
  isTypeahead = false,
  showShadow = true,
  trackingCategory = 'Select Box',
  isLocked,
  errors,
  hideErrorMessage,
  hideLabel,
  size = 'medium',
  tooltipContent,
  ...props
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [dropdownPosition, setDropdownPosition] = useState<RectPositionInterface>({ left: 0, top: 0 });
  const [filteredOptions, setFilteredOptions] = useState(options);
  const fieldError = get(errors, id);
  const errorMessage = fieldError?.message;

  const setLockedDialogIsOpen = useSetRecoilState(lockedDialogIsOpenState);

  const handleAnalyticsEvent = (selectedItem: OptionItem) => {
    // Analytics Event
    trackUser.event(`Change: ${trackingCategory}`, { value: selectedItem.value, id });
  };

  const handleLockedDialogOpen = () => {
    setLockedDialogIsOpen(true);
    // Analytics Event
    trackUser.event(`Click Locked: ${trackingCategory}`, { id });
  };

  function stateReducer(
    state: UseComboboxState<OptionItem>,
    actionAndChanges: UseComboboxStateChangeOptions<OptionItem>,
  ) {
    const { type, changes } = actionAndChanges;
    const { inputValue, isOpen } = changes;
    const { selectedItem: newSelectedItem } = changes;

    if (
      type === useCombobox.stateChangeTypes.InputKeyDownEnter ||
      type === useCombobox.stateChangeTypes.ItemClick ||
      type === useCombobox.stateChangeTypes.FunctionSelectItem
    ) {
      // Don't allow selection of disabled item
      if (newSelectedItem?.disabled) {
        // Either keep current selection or do nothing
        changes.selectedItem = state.selectedItem || undefined;
      }

      // Only fire Analytics events if the change was user triggered (ie. menu was open)
      if (isOpen && newSelectedItem) {
        handleAnalyticsEvent(newSelectedItem);
      }

      return {
        ...changes,
        // @NOTE: isOpen: false shouldn't be needed but there seems to be
        // a race condition with the menu closing,it works fine with the
        // keyboard but not with the mouse
        isOpen: false,
        // We need to clear the inputValue so it's not filtering the list when
        // we're not typing/searching so you can browse the list as normal
        inputValue: '',
      };
    }

    if (type === useCombobox.stateChangeTypes.InputBlur) {
      return {
        ...changes,
        inputValue: '',
      };
    }

    if (type === useCombobox.stateChangeTypes.InputChange) {
      // If there is no inputValue clear the selected item
      if (!inputValue) {
        changes.selectedItem = undefined;
      }
    }

    // Continue
    return changes;
  }

  const {
    isOpen,
    closeMenu,
    selectedItem,
    selectItem,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    getInputProps,
    openMenu,
    setHighlightedIndex,
    reset,
  } = useCombobox({
    id: `select-${id}`,
    items: flattenGroupOptions(filteredOptions),
    defaultSelectedItem,
    itemToString: convertItemToString,
    onInputValueChange({ inputValue }) {
      if (inputValue) setFilteredOptions(getFilteredOptions(inputValue, options));
    },
    onSelectedItemChange: ({ selectedItem: selection }) => {
      if (isFunction(onSelect) && selection) onSelect(selection);
      if (isOpen) closeMenu();
    },
    onIsOpenChange: ({ isOpen: menuIsOpening }) => {
      if (menuIsOpening) {
        updateDropdownPosition();
      }
    },
    stateReducer,
  });

  useEffect(() => {
    // If the value is missing
    if (!selected) reset();
    // If the value has changed and is not the same
    if (selected && selected !== selectedItem?.value) {
      const item = flattenGroupOptions(options).find((option) => option.value === selected);

      if (item) {
        selectItem(item);
      } else {
        reset();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  // If the options change reselect the same value from the new options
  useEffect(() => {
    if (watchForOptionsChange) {
      const item = flattenGroupOptions(options).find((option) => option.value === selected);
      if (item) {
        selectItem(item);
      }
    }

    if (inputRef.current?.value !== undefined) {
      // We need to update internal state when the options change
      setFilteredOptions(getFilteredOptions(inputRef.current.value, options));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  // Handle dynamic positioning of dropdown
  useEffect(() => {
    function handleResize() {
      updateDropdownPosition();
    }

    const debounced = debounce(handleResize, DEBOUNCE_DELAY);
    window.addEventListener(`resize`, debounced);

    return () => {
      window.removeEventListener(`resize`, debounced);
    };
  }, []);

  const updateDropdownPosition = () => {
    if (inputRef.current) {
      const { x: left, y: top, height } = inputRef.current.getBoundingClientRect();

      // Account for scroll position
      const topPosition = top + height + window.scrollY;

      setDropdownPosition({ left, top: topPosition });
    }
  };

  // If there is only a single option available highlight
  // it so you can press enter to select it
  useEffect(
    () => {
      if (checkIfOnlyOneItemLeft(filteredOptions) && highlightedIndex === -1) {
        setHighlightedIndex(0);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredOptions],
  );

  return (
    <Wrapper
      position="relative"
      variant={variant}
      size={size}
      className={fieldError && 'has-error'}
      buttonWidth={buttonWidth}
      {...getComboboxProps()}
      {...props}
    >
      <Flex>
        <Label {...getLabelProps()} isHidden={!label} variant={variant}>
          {label || 'Select an option'}
        </Label>
        {tooltipContent && (
          <TooltipTrigger>
            <Tooltip content={tooltipContent} variant="dark" maxWidth="260px">
              <span>
                <Icon name="question-mark-circled" size={3} />
              </span>
            </Tooltip>
          </TooltipTrigger>
        )}
      </Flex>

      <InputWrapper className="input-wrapper">
        <Input
          {...getInputProps({
            placeholder: !selectedItem ? placeholder : undefined,
            ref: inputRef,
            onFocus: !isLocked ? openMenu : () => handleLockedDialogOpen(),
          })}
          isOpen={isOpen}
          minWidth={minWidth}
          maxWidth={maxWidth}
          onBlur={onBlur}
          disabled={disabled}
          isActive={isActive}
          isTypeahead={isTypeahead}
          showShadow={showShadow}
          data-testid="select-box-input"
          autocomplete="off"
          tabIndex={!isLocked ? null : '-1'}
        />
        <FakeInputValue className="select-box-fake-input-value">
          {!getInputProps().value ? selectedItem?.label : null}
        </FakeInputValue>

        <ToggleButton
          {...getToggleButtonProps()}
          disabled={disabled || isLocked}
          as="button"
          className="select-box-toggle-button"
        >
          <StyledIcon
            name={!isLocked ? 'down' : 'lock'}
            size="base"
            transform={isOpen ? 'rotate(-180deg)' : undefined}
            color={isLocked ? `${colors.states.locked} !important` : undefined}
          />
          <VisuallyHidden>Toggle dropdown</VisuallyHidden>
        </ToggleButton>
      </InputWrapper>

      <Menu
        {...getMenuProps({}, { suppressRefError: true })}
        isOpen={isOpen}
        options={filteredOptions}
        getItemProps={getItemProps}
        convertItemToString={convertItemToString}
        dropdownWidth={dropdownWidth}
        dropdownPosition={dropdownPosition}
        selectedItem={selectedItem}
        selectItem={selectItem}
        highlightedIndex={highlightedIndex}
        size={size}
      />
      {errorMessage && !hideErrorMessage && <Error className="error mb-sm">{errorMessage}</Error>}
    </Wrapper>
  );
};

const variants = styledVariant({
  scale: 'forms.selectBox',
  variants: {
    light: {},
    dark: {},
  },
});

const sizeVariants = styledVariant({
  scale: 'forms.selectBoxSizes',
  prop: 'size',
  variants: {
    medium: {},
    small: {},
  },
});

const Wrapper = styled(Box)`
  ${variants};
  ${sizeVariants};
  position: relative;
`;

const ToggleButton = styled(Box)`
  align-items: center;
  border: none !important;
  display: inline-flex;
  height: 100%;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  &:disabled {
    pointer-events: none;
  }
`;

const StyledIcon = styled(Icon)``;

// We use a fake input value to display so we can use the actual
// input value to filter only when the user is typing
const FakeInputValue = styled.span`
  min-width: ${themeGet('space.base')};
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: ${themeGet('space.lg')};
  text-overflow: ellipsis;
  top: 50%;
  transform: translateY(-50%);
  white-space: nowrap;
`;

const InputWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all linear 150ms;
  position: relative;

  ${({ isHidden }) =>
    isHidden &&
    css`
      display: none;
    `};
`;

const Input = styled.input<SelectInputProps>`
  ${layout};
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${themeGet('fontSizes.baseSm')};
  border-radius: ${themeGet('radii.md')};
  transition: all linear 150ms;
  width: 100%;
  min-width: ${({ minWidth }) => minWidth || '0'};
  max-width: ${({ maxWidth }) => maxWidth || 'none'};

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: ${themeGet('space.base')};
  }

  ${({ showShadow }) =>
    showShadow &&
    css`
      box-shadow: ${themeGet('shadows.softer')};
    `};

  ${({ isOpen }) =>
    isOpen &&
    css`
      border-color: ${themeGet('colors.grey.700')} !important;
    `}

  ${({ isActive }) =>
    isActive &&
    css`
      border-color: ${themeGet('colors.lightBlue.600')} !important;
    `}

    ${({ isTypeahead }) =>
    !isTypeahead &&
    css`
      cursor: pointer;
      caret-color: transparent;
    `};
`;

const TooltipTrigger = styled.div`
  cursor: pointer;
  margin-bottom: ${themeGet('space.sm')};
  margin-left: auto;
`;

export { SelectBox };
